exports.components = {
  "component---src-pages-amulya-js": () => import("./../../../src/pages/amulya.js" /* webpackChunkName: "component---src-pages-amulya-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-book-a-demo-js": () => import("./../../../src/pages/book-a-demo.js" /* webpackChunkName: "component---src-pages-book-a-demo-js" */),
  "component---src-pages-compliance-training-aoda-js": () => import("./../../../src/pages/compliance-training-aoda.js" /* webpackChunkName: "component---src-pages-compliance-training-aoda-js" */),
  "component---src-pages-compliance-training-health-and-safety-js": () => import("./../../../src/pages/compliance-training-health-and-safety.js" /* webpackChunkName: "component---src-pages-compliance-training-health-and-safety-js" */),
  "component---src-pages-compliance-training-ipac-js": () => import("./../../../src/pages/compliance-training-ipac.js" /* webpackChunkName: "component---src-pages-compliance-training-ipac-js" */),
  "component---src-pages-compliance-training-whmis-js": () => import("./../../../src/pages/compliance-training-whmis.js" /* webpackChunkName: "component---src-pages-compliance-training-whmis-js" */),
  "component---src-pages-compliance-trainings-js": () => import("./../../../src/pages/compliance-trainings.js" /* webpackChunkName: "component---src-pages-compliance-trainings-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-covid-19-blog-js": () => import("./../../../src/pages/covid19-blog.js" /* webpackChunkName: "component---src-pages-covid-19-blog-js" */),
  "component---src-pages-covid-compliance-js": () => import("./../../../src/pages/covid-compliance.js" /* webpackChunkName: "component---src-pages-covid-compliance-js" */),
  "component---src-pages-dataloggers-js": () => import("./../../../src/pages/dataloggers.js" /* webpackChunkName: "component---src-pages-dataloggers-js" */),
  "component---src-pages-drcarroll-js": () => import("./../../../src/pages/drcarroll.js" /* webpackChunkName: "component---src-pages-drcarroll-js" */),
  "component---src-pages-drclaudio-js": () => import("./../../../src/pages/drclaudio.js" /* webpackChunkName: "component---src-pages-drclaudio-js" */),
  "component---src-pages-drmarklin-js": () => import("./../../../src/pages/drmarklin.js" /* webpackChunkName: "component---src-pages-drmarklin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relations-js": () => import("./../../../src/pages/investor-relations.js" /* webpackChunkName: "component---src-pages-investor-relations-js" */),
  "component---src-pages-investors-relations-js": () => import("./../../../src/pages/investors-relations.js" /* webpackChunkName: "component---src-pages-investors-relations-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-p-compliance-trainings-js": () => import("./../../../src/pages/p-compliance-trainings.js" /* webpackChunkName: "component---src-pages-p-compliance-trainings-js" */),
  "component---src-pages-partner-with-us-js": () => import("./../../../src/pages/partner-with-us.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-partnerwithus-js": () => import("./../../../src/pages/partnerwithus.js" /* webpackChunkName: "component---src-pages-partnerwithus-js" */),
  "component---src-pages-practice-building-js": () => import("./../../../src/pages/practice-building.js" /* webpackChunkName: "component---src-pages-practice-building-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-resource-centre-js": () => import("./../../../src/pages/resource-centre.js" /* webpackChunkName: "component---src-pages-resource-centre-js" */),
  "component---src-pages-sterilwize-connect-js": () => import("./../../../src/pages/sterilwize-connect.js" /* webpackChunkName: "component---src-pages-sterilwize-connect-js" */),
  "component---src-pages-sterilwize-connect-model-1-js": () => import("./../../../src/pages/sterilwize-connect-model1.js" /* webpackChunkName: "component---src-pages-sterilwize-connect-model-1-js" */),
  "component---src-pages-sterilwize-connect-model-2-js": () => import("./../../../src/pages/sterilwize-connect-model2.js" /* webpackChunkName: "component---src-pages-sterilwize-connect-model-2-js" */),
  "component---src-pages-sterilwize-connect-model-3-js": () => import("./../../../src/pages/sterilwize-connect-model3.js" /* webpackChunkName: "component---src-pages-sterilwize-connect-model-3-js" */),
  "component---src-pages-sterilwize-js": () => import("./../../../src/pages/sterilwize.js" /* webpackChunkName: "component---src-pages-sterilwize-js" */),
  "component---src-pages-sterilwize-system-js": () => import("./../../../src/pages/sterilwize-system.js" /* webpackChunkName: "component---src-pages-sterilwize-system-js" */),
  "component---src-pages-submit-testimonial-js": () => import("./../../../src/pages/submit-testimonial.js" /* webpackChunkName: "component---src-pages-submit-testimonial-js" */),
  "component---src-pages-susan-js": () => import("./../../../src/pages/susan.js" /* webpackChunkName: "component---src-pages-susan-js" */),
  "component---src-pages-team-details-js": () => import("./../../../src/pages/teamDetails.js" /* webpackChunkName: "component---src-pages-team-details-js" */),
  "component---src-pages-team-resume-js": () => import("./../../../src/pages/team-resume.js" /* webpackChunkName: "component---src-pages-team-resume-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-ushi-js": () => import("./../../../src/pages/ushi.js" /* webpackChunkName: "component---src-pages-ushi-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */)
}

